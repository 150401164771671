import { Injectable } from "@angular/core";
import { Resolve } from '@angular/router';
import { ContentService } from "app/shared/content.service";

@Injectable()
export class HomeResolver implements Resolve<any> {
	constructor(
		private contentService: ContentService,
	) {
		//
	}

	resolve() {
		return this.contentService.getContent();
	}
}