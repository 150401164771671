import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { SharedService } from "./shared.service";

@Injectable()
export class ContentService {

	constructor(
		private http: HttpClient,
		public shared: SharedService,
	) {
		//
	}

	getContent() {
		this.http.get('/api/site/data').subscribe(
			(data: any) => {
				this.shared.content = data;
			}
		);
	}
}
