import {
	Component,
	OnInit,
	OnDestroy,
	Inject,
	Optional,
	ElementRef,
	ViewChild,
	HostListener,
} from "@angular/core";
import { DOCUMENT } from "@angular/common";

import { AppInfoService } from "../../shared";
import { SharedService } from "app/shared/shared.service";

export function isHtmlLinkElement(
	element: Element,
): element is HTMLLinkElement {
	return element.tagName.toLowerCase() === "a";
}

@Component({
	selector: "app-nav",
	templateUrl: "./nav.component.html",
	styleUrls: ["./nav.component.scss"],
})
export class NavComponent implements OnInit, OnDestroy {
	links = [
		// { path: ["/"], title: "Home", activeOptions: { exact: true } },
		{ path: ["/projects"], title: "Projects" },
	];

	appTitle = this.appInfo.title;
	appVersion = this.appInfo.version;
	appEnv = this.appInfo.environment;
	isDebug = this.appInfo.isDebug;

	socialBar: any;
	menuBar: any;

	isMenuOpened = false;
	@ViewChild("menu") menuElementRef: ElementRef | undefined;

	private domClickListener$$!: () => void;

	constructor(
		@Optional()
		@Inject(DOCUMENT)
		private appInfo: AppInfoService,
		public shared: SharedService,
	) { }

	ngOnInit(): void {
		this.socialBar = document.getElementsByClassName('social')[0] as HTMLElement;
		this.menuBar = document.getElementsByClassName('menu')[0] as HTMLElement;
	}

	openMenu() {
		this.isMenuOpened = !this.isMenuOpened;
		const offsetTop = window.pageYOffset;
		if (!this.isMenuOpened && offsetTop <= 50) {
			this.menuBar.classList.remove('small');
		} else {
			this.menuBar.classList.add('small');
		}
	}

	ngOnDestroy(): void {
		this.domClickListener$$();
	}

	@HostListener('window:scroll', ['$event'])
	collapseMenu() {
		const offsetTop = window.pageYOffset;
		if (offsetTop >= 50) {
			this.socialBar.classList.add('hide');
			this.menuBar.classList.add('small');
		} else {
			this.socialBar.classList.remove('hide');
			this.menuBar.classList.remove('small');
		}
	}
}
