import { Component } from "@angular/core";

import { AppInfoService } from "../../../shared";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: "app-modalsuccess",
	templateUrl: "./modalSuccess.component.html",
	styleUrls: ["./modalSuccess.component.scss"],
})
export class ModalSuccessComponent {
	title = this.appInfo.title;

	constructor(private appInfo: AppInfoService, public activeModal: NgbActiveModal) {}
}
