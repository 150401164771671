import { NgModule } from "@angular/core";

import { AppInfoService } from "./app-info.service";
import { ContentService } from "./content.service";
import { SharedService } from "./shared.service";

@NgModule({
	providers: [
		AppInfoService,
		ContentService,
		SharedService,
	],
})
export class AppSharedModule {}
