import { Component, OnInit, AfterViewInit } from "@angular/core";

import { AppInfoService } from "../../../shared";
import { HttpClient } from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalSuccessComponent } from "../modalSuccess/modalSuccess.component";
import { SharedService } from "app/shared/shared.service";

@Component({
	selector: "app-header",
	templateUrl: "./header.component.html",
	styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, AfterViewInit {
	title = this.appInfo.title;
	registrationForm: FormGroup;
	errorMessages: any = [];
	mainTitleArray: any = ['de nincs meg a nagy ötlet?', 'DE NEM MERSZ EGYEDÜL LÉPNI?'];
	mainTitle = '';


	constructor(
		private appInfo: AppInfoService,
		private http: HttpClient,
		private modalService: NgbModal,
		public shared: SharedService,
	) {
		this.registrationForm = new FormGroup({
			firstName: new FormControl('', Validators.required),
			lastName: new FormControl('', Validators.required),
			email: new FormControl('', [
				Validators.required,
				Validators.email,
			]),
			privacy_policy: new FormControl(false, Validators.required)
		});
	}

	ngOnInit() {
		//
	}

	ngAfterViewInit() {
		this.mainTitleArray = this.shared.content.roll_text_j;
		const elem = document.getElementById('mainTitle') as HTMLElement;
		let index = 0;
		this.mainTitle = this.mainTitleArray[index].text;
		setInterval(() => {
			elem.classList.forEach((e: any) => {
				this.mainTitle = this.mainTitleArray[index].text;
				if (e === 'fadeIn') {
					index++;
					elem.classList.replace('fadeIn', 'fadeOut');
					if (index >= this.mainTitleArray.length) {
						index = 0;
					}
				} else if (e === 'fadeOut') {
					elem.classList.replace('fadeOut', 'fadeIn');
				}
			});
		}, 2000);
	}

	registration(formData: any) {
		this.errorMessages = [];
		const data = {
			first_name: formData.firstName,
			last_name: formData.lastName,
			email: formData.email,
		};
		this.http.post('/api/applicant/create', data).subscribe(
			d => {
				console.log(d);
				this.modalService.open(ModalSuccessComponent);
				this.registrationForm.reset();
			},
			error => {
				console.log('[ERROR LOG]: ', error.error.errors);
				Object.keys(error.error.errors).forEach(e => {
					console.log('[ERROR]: ' + error.error.errors[e]);
					this.errorMessages.push(error.error.errors[e]);
				});
			}
		);
	}
}
