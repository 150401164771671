import { Component } from "@angular/core";

import { AppInfoService } from "../../../shared";
import { SharedService } from "app/shared/shared.service";

@Component({
	selector: "app-description",
	templateUrl: "./description.component.html",
	styleUrls: ["./description.component.scss"],
})
export class DescriptionComponent {
	title = this.appInfo.title;

	constructor(
		private appInfo: AppInfoService,
		public shared: SharedService,
	) {}
}
