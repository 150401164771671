import { Component } from "@angular/core";

import { AppInfoService } from "../../../shared";
import { HttpClient } from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalSuccessComponent } from "../modalSuccess/modalSuccess.component";
import { SharedService } from "app/shared/shared.service";

@Component({
	selector: "app-footer",
	templateUrl: "./footer.component.html",
	styleUrls: ["./footer.component.scss"],
})
export class FooterComponent {
	title = this.appInfo.title;
	registrationForm: FormGroup;
	errorMessages: any = [];


	constructor(
		private appInfo: AppInfoService,
		private http: HttpClient,
		private modalService: NgbModal,
		public shared: SharedService,
	) {
		this.registrationForm = new FormGroup({
			firstName: new FormControl('', Validators.required),
			lastName: new FormControl('', Validators.required),
			email: new FormControl('', [
				Validators.required,
				Validators.email,
			]),
			privacy_policy_footer: new FormControl(false, Validators.required)
		});
	}

	registration(formData: any) {
		this.errorMessages = [];
		console.log('DATA: ', formData);
		const data = {
			first_name: formData.firstName,
			last_name: formData.lastName,
			email: formData.email,
		};
		this.http.post('/api/applicant/create', data).subscribe(
			d => {
				console.log(d);
				this.modalService.open(ModalSuccessComponent);
				this.registrationForm.reset();
			},
			error => {
				console.log('[ERROR LOG]: ', error.error.errors);
				Object.keys(error.error.errors).forEach(e => {
					console.log('[ERROR]: ' + error.error.errors[e]);
					this.errorMessages.push(error.error.errors[e]);
				});
			}
		);
	}
}
