import { Routes } from '@angular/router';

import { NavComponent } from "./nav/nav.component";
import { HomeComponent } from "./home/home.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { HeaderComponent } from "./sections/header/header.component";
import { DescriptionComponent } from "./sections/description/description.component";
import { ProgramsComponent } from "./sections/programs/programs.component";
import { FooterComponent } from "./sections/footer/footer.component";
import { ModalSuccessComponent } from "./sections/modalSuccess/modalSuccess.component";
import { HomeResolver } from './home.resolver';

export const AREAS_ROUTES: Routes = [
	{
		path: "",
		component: HomeComponent,
		pathMatch: "full",
		resolve: {
			routeResolver: HomeResolver
		}
	},
	{ path: "**", component: NotFoundComponent },
];

export const AREAS_COMPONENTS = [
	HomeComponent,
	NavComponent,
	HeaderComponent,
	DescriptionComponent,
	ProgramsComponent,
	FooterComponent,
	NotFoundComponent,
	ModalSuccessComponent,
];
